import React, { useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Card, Modal, Portal, IconButton } from 'react-native-paper';
import ReactJson from 'react-json-view';
import Button from '../../components/Button';
import { Text } from '@ui-kitten/components';
import { UIStatus, UIStatusWrapper } from '../../components/ui-status';
import { StyleSheet } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import BalanceInfo from '../user-account/BalanceInfo';
import { useIsMobile } from '../../core/responsive.utils';
import TransactionHistory from '../user-account/TransactionHistory';
import { AddTransaction } from './AddTransaction';
import {
  jsonToExcel,
  parseShippingRateSheet,
  shippingRatesToExcel,
  unifiedAlert,
} from '../../core/utils/utils';
import { saveAs } from 'file-saver';
import { isEmpty } from 'ramda';
import { paperNativeTheme } from '../../core/theme';
import { ListInvoice } from '../../components/ListInvoice';
import { client } from '../../store';

const UPDATE_WAREHOUSE_RATES = gql`
  mutation UpdateOrgRates($orgId: ID!, $rates: JSON!) {
    updateOrgRates(orgId: $orgId, rates: $rates) {
      id
      rates
    }
  }
`;

const GET_TRANSACTIONS_BY_ORG = gql`
  query TransactionsByOrg($input: TransactionInput) {
    transactionsByOrg(input: $input) {
      data {
        id
        type
        amount
        createdTime
        remark
        remarkExtra
        status
        createdByUser {
          id
        }
        isManuallyCreated
      }
      total
    }
  }
`;

const CREATE_OR_REPLACE_ORG_SHIPPING_RATES = gql`
  mutation createOrReplaceOrgShippingRates(
    $orgId: ID!
    $shippingRates: [ShippingRateInput!]!
    $deliveryZones: [DeliveryInput!]!
    $lastMileSurcharges: [LastMileSurchargeInput!]!
    $rasCharges: [RasChargeInput!]!
  ) {
    createOrReplaceOrgShippingRates(
      orgId: $orgId
      shippingRates: $shippingRates
      deliveryZones: $deliveryZones
      lastMileSurcharges: $lastMileSurcharges
      rasCharges: $rasCharges
    )
  }
`;

const styles = StyleSheet.create({
  card: {
    marginBottom: '1em',
    border: '1px solid #F2F2F2',
  },

  accountBalance: {
    display: 'flex',
  },

  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },

  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20 },
  modalStyle: { backgroundColor: 'white', marginHorizontal: 'auto', width: '90%', maxWidth: 1680 },
});

export const OrgCard = ({ org, onSaveRates, onDeleteOrg }) => {
  const [warehouseRates, setWarehouseRates] = useState();
  const [shippingRates, setShippingRate] = useState();
  const [updateWarehouseRates, updateDatesResult] = useMutation(UPDATE_WAREHOUSE_RATES);
  const [transHistoryVisible, setTransHistoryVisible] = useState(false);
  const [addTransactionVisible, setAddTransactionVisible] = useState(false);
  const [refetchBalance, setRefetchBalance] = useState(false);

  const DefaultTransactionParams = {
    pageNo: 0,
    pageSize: 20,
    orgId: org.id,
  };
  const [transactionParams, setTransactionParams] = useState(DefaultTransactionParams);

  const isMobile = useIsMobile();
  const [
    loadTransactions,
    { data: transResponse, loading: loadingTrans, error: loadingTransError },
  ] = useLazyQuery(GET_TRANSACTIONS_BY_ORG);

  const [
    createOrReplaceShippingRates,
    { loading: createOrReplaceSRLoading, error: createOrReplaceSRError },
  ] = useMutation(CREATE_OR_REPLACE_ORG_SHIPPING_RATES);

  const pickDocument = async (callback) => {
    let result = await DocumentPicker.getDocumentAsync({});
    const XLSX = await import('xlsx/xlsx.mjs');

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'array' });

      const json = workbook.SheetNames.reduce((a, sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
          raw: false,
          rawNumbers: true,
        });

        return {
          ...a,
          [sheetName]: json,
        };
      }, {});

      callback(json);
    };
    reader.readAsArrayBuffer(result.file);
  };

  const getTransactionParamsForRequest = (params) => {
    const { range, ...otherParams } = params;
    let newParams = { ...otherParams };
    if (range) {
      newParams = { ...otherParams, ...range };
    }
    return newParams;
  };

  const loadTransactionWithParams = (params) => {
    const data = getTransactionParamsForRequest(params);
    loadTransactions({ variables: { input: data } });
  };

  return (
    <Card style={styles.card} key={org.id}>
      <Portal>
        <Modal
          visible={transHistoryVisible}
          onDismiss={() => setTransHistoryVisible(false)}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <UIStatusWrapper status={new UIStatus(loadingTrans, loadingTransError)}>
            <TransactionHistory
              displayExtra={true}
              transactions={(transResponse && transResponse.transactionsByOrg.data) || []}
              total={(transResponse && transResponse.transactionsByOrg.total) || 0}
              transactionParams={transactionParams}
              onChangeTransactionParams={(params) => {
                setTransactionParams(params);
                loadTransactionWithParams(params);
              }}
              getExportData={async ({ listId }) => {
                const params = getTransactionParamsForRequest(transactionParams);
                const data = await client.query({
                  query: GET_TRANSACTIONS_BY_ORG,
                  variables: {
                    input: { ...params, listId, isGetAll: true },
                  },
                  fetchPolicy: 'network-only',
                });
                return data.data?.transactionsByOrg?.data;
              }}
              defaultParams={DefaultTransactionParams}
            />
          </UIStatusWrapper>
        </Modal>

        <Modal
          visible={addTransactionVisible}
          onDismiss={() => setAddTransactionVisible(false)}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <AddTransaction
            orgId={org.id}
            onAdded={() => {
              setAddTransactionVisible(false);
              setRefetchBalance((p) => !p);
            }}
          />
        </Modal>
      </Portal>

      <Card.Title
        title={org.name}
        left={(props) => <Avatar.Icon {...props} icon="account-supervisor-circle" />}
        right={
          onDeleteOrg
            ? (props) => (
                <IconButton
                  {...props}
                  icon="account-off-outline"
                  color={paperNativeTheme.colors.danger}
                  size={30}
                  onPress={() => onDeleteOrg(org)}
                />
              )
            : null
        }
      />

      <Card.Content>
        <Card style={styles.card}>
          <Card.Title title="Info" />
          <Card.Content>
            <ReactJson src={org} collapsed={true} name={null} displayDataTypes={false} />
          </Card.Content>
        </Card>

        <Card style={styles.card}>
          <Card.Title title="Account balance" />
          <Card.Content>
            <BalanceInfo orgId={org.id} refetch={refetchBalance} hideAddCreditButton={true} />

            <Button
              icon="cash-multiple"
              mode="outlined"
              onPress={() => {
                setAddTransactionVisible(true);
              }}>
              Create transaction
            </Button>
          </Card.Content>

          <Card.Actions style={styles.btnGroup}>
            <Button
              icon="history"
              mode="contained"
              onPress={() => {
                loadTransactionWithParams(transactionParams);
                setTransHistoryVisible(true);
              }}>
              View history
            </Button>
          </Card.Actions>
        </Card>

        <ListInvoice showLogOutButton={false} organisationId={org.id} />

        <Card style={styles.card}>
          <Card.Title title="Rates" />
          <Card.Content>
            <Card style={styles.card}>
              <Card.Title title="Warehouse rates" />
              <Card.Content>
                {org.rates ? (
                  <React.Fragment>
                    <ReactJson
                      src={org.rates}
                      collapsed={true}
                      name={null}
                      displayDataTypes={false}
                    />

                    <Button
                      icon="download"
                      mode="outlined"
                      onPress={() => jsonToExcel(org.rates, 'warehouse_rates.xlsx')}>
                      Download warehouse rates
                    </Button>
                  </React.Fragment>
                ) : (
                  <Text status="info">No warehouse rates found.</Text>
                )}

                <UIStatusWrapper
                  status={new UIStatus(updateDatesResult.loading, updateDatesResult.error)}>
                  <Card style={styles.card}>
                    <Card.Content>
                      <Button
                        icon="upload"
                        mode="outlined"
                        onPress={() => pickDocument(setWarehouseRates)}>
                        Upload & preview warehouse rates
                      </Button>
                    </Card.Content>

                    <Card.Actions>
                      {warehouseRates && (
                        <Button
                          icon="content-save"
                          mode="contained"
                          onPress={() => {
                            updateWarehouseRates({
                              variables: {
                                orgId: org.id,
                                rates: warehouseRates,
                              },
                            }).then(onSaveRates);
                          }}>
                          Save warehouse rates
                        </Button>
                      )}
                    </Card.Actions>
                  </Card>
                </UIStatusWrapper>
              </Card.Content>
            </Card>

            <Card style={styles.card}>
              <Card.Title title="Shipping rates" />
              <Card.Content>
                {!isEmpty(org.shippingRates) ||
                !isEmpty(org.deliveryZones) ||
                !isEmpty(org.lastMileSurcharges) ||
                !isEmpty(org.rasCharges) ? (
                  <Button
                    icon="download"
                    mode="outlined"
                    onPress={async () => {
                      const wbout = await shippingRatesToExcel(
                        org.shippingRates,
                        org.deliveryZones,
                        org.lastMileSurcharges,
                        org.rasCharges,
                      );
                      const blobFile = new Blob([new Uint8Array(wbout)], {
                        type: 'application/octet-stream',
                      });
                      saveAs(blobFile, `OrgShippingRates_${org.name}.xlsx`);
                    }}>
                    Download shipping rates
                  </Button>
                ) : (
                  <Text status="info">No shipping rates found.</Text>
                )}

                <UIStatusWrapper
                  status={new UIStatus(updateDatesResult.loading, updateDatesResult.error)}>
                  <Card style={styles.card}>
                    <Card.Content>
                      <Button
                        icon="upload"
                        mode="outlined"
                        onPress={() => pickDocument(setShippingRate)}>
                        Upload & preview shipping rates
                      </Button>
                      {shippingRates && (
                        <ReactJson
                          src={shippingRates}
                          collapsed={true}
                          name={null}
                          displayDataTypes={false}
                        />
                      )}
                    </Card.Content>

                    <Card.Actions>
                      {shippingRates && (
                        <Button
                          icon="content-save"
                          mode="contained"
                          disabled={createOrReplaceSRLoading}
                          onPress={() => {
                            const {
                              shippingRates: rates,
                              deliveryZones,
                              lastMileSurcharges,
                              rasCharges,
                              errors,
                            } = parseShippingRateSheet(shippingRates);

                            if (errors.length > 0) {
                              unifiedAlert(errors.join('\n'));
                              return;
                            }

                            createOrReplaceShippingRates({
                              variables: {
                                orgId: org.id,
                                shippingRates: rates,
                                deliveryZones,
                                lastMileSurcharges,
                                rasCharges,
                              },
                            })
                              .then(onSaveRates)
                              .catch((error) =>
                                unifiedAlert('Failed! ' + JSON.stringify(error.message || error)),
                              );
                          }}>
                          Save shipping rates
                        </Button>
                      )}
                    </Card.Actions>
                  </Card>
                </UIStatusWrapper>
              </Card.Content>
            </Card>
          </Card.Content>
        </Card>
      </Card.Content>
    </Card>
  );
};
