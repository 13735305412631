import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';

const WAREHOUSE_ACCOUNTS = gql`
  query warehouseAccounts {
    warehouseAccounts {
      id
      name
      provider
      country
      expiredTime
      accountNumber
      isMasterAccount
      masterAccountAdminOrganisationId
      connectionError
    }
  }
`;

const UPDATE_WMS = gql`
  mutation UpdateWarehouseAccount(
    $warehouseAccountId: ID!
    $name: String!
    $accountNumber: String!
  ) {
    updateWarehouseAccount(
      warehouseAccountId: $warehouseAccountId
      name: $name
      accountNumber: $accountNumber
    ) {
      name
      accountNumber
    }
  }
`;

const DELETE_WAREHOUSE_ACCOUNT = gql`
  mutation DeleteWarehouseAccount($id: ID!) {
    deleteWarehouseAccount(warehouseAccountId: $id)
  }
`;

const ADD_WAREHOUSE_ACCOUNT = gql`
  mutation addWarehouseAccount(
    $name: String!
    $provider: WarehouseProvider!
    $authorisationCode: String
    $redirectUrl: String
  ) {
    addWarehouseAccount(
      input: {
        name: $name
        provider: $provider
        authorisationCode: $authorisationCode
        redirectUrl: $redirectUrl
      }
    ) {
      id
      name
      provider
      appKey
      country
    }
  }
`;

const SET_ORGANISATION_MASTER_WAREHOUSE_ACCOUNT = gql`
  mutation setOrganisationMasterWarehouseAccount(
    $organisationId: ID!
    $masterWarehouseAccountId: ID!
  ) {
    setOrganisationMasterWarehouseAccount(
      organisationId: $organisationId
      masterWarehouseAccountId: $masterWarehouseAccountId
    )
  }
`;

const DELETE_ORGANISATION_MASTER_WAREHOUSE_ACCOUNT = gql`
  mutation deleteOrganisationMasterWarehouseAccount(
    $organisationId: ID!
    $masterWarehouseAccountId: ID!
  ) {
    deleteOrganisationMasterWarehouseAccount(
      organisationId: $organisationId
      masterWarehouseAccountId: $masterWarehouseAccountId
    )
  }
`;

export class WarehouseAccountStore {
  loading = false;
  error = null;
  cachedWarehouseAccounts = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
      },
      { autoBind: true },
    );
  }

  *getWarehouseAccountIds() {
    if (!this.cachedWarehouseAccounts) {
      yield this.fetchItems();
    }
    return (this.cachedWarehouseAccounts || []).map((s) => s.id);
  }

  *getWarehouseAccounts() {
    if (!this.cachedWarehouseAccounts) {
      yield this.fetchItems();
    }
    return this.cachedWarehouseAccounts || [];
  }

  *getWarehouseAccountById(warehouseAccountId) {
    if (!this.cachedWarehouseAccounts) {
      yield this.fetchItems();
    }
    return (this.cachedWarehouseAccounts || []).find(({ id }) => id === warehouseAccountId);
  }

  get warehouseAccountId() {
    if (!this.cachedWarehouseAccounts) {
      this.fetchItems();
    }
    return this.cachedWarehouseAccounts?.[0]?.id;
  }

  get warehouseAccounts() {
    if (!this.cachedWarehouseAccounts) {
      this.fetchItems();
    }
    return this.cachedWarehouseAccounts || [];
  }

  get warehouseAccountIds() {
    if (!this.cachedWarehouseAccounts) {
      this.fetchItems();
    }
    return (this.cachedWarehouseAccounts || []).map((s) => s.id);
  }

  *updateWmsAccount(warehouseAccountId, name, accountNumber) {
    yield this.client.mutate({
      mutation: UPDATE_WMS,
      variables: {
        warehouseAccountId: warehouseAccountId,
        name: name,
        accountNumber: accountNumber,
      },
    });
    yield this.fetchItems();
  }

  *deleteWmsAccount(warehouseAccountId) {
    yield this.client.mutate({
      mutation: DELETE_WAREHOUSE_ACCOUNT,
      variables: {
        id: warehouseAccountId,
      },
    });
    yield this.fetchItems();
  }

  *addWmsAccount(name, provider, authorisationCode, redirectUrl) {
    const {
      data: { addWarehouseAccount },
    } = yield this.client.mutate({
      mutation: ADD_WAREHOUSE_ACCOUNT,
      variables: {
        name,
        provider,
        authorisationCode,
        redirectUrl,
      },
    });
    yield this.fetchItems();
    return addWarehouseAccount;
  }

  *fetchItems() {
    try {
      this.loading = true;
      const { data } = yield this.client.query({
        query: WAREHOUSE_ACCOUNTS,
        fetchPolicy: 'no-cache',
      });
      this.cachedWarehouseAccounts = data?.warehouseAccounts;
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *setOrganisationMasterWarehouseAccount(organisationId, masterWarehouseAccountId) {
    yield this.client.mutate({
      mutation: SET_ORGANISATION_MASTER_WAREHOUSE_ACCOUNT,
      variables: {
        organisationId,
        masterWarehouseAccountId,
      },
    });
  }

  *deleteOrganisationMasterWarehouseAccount(organisationId, masterWarehouseAccountId) {
    yield this.client.mutate({
      mutation: DELETE_ORGANISATION_MASTER_WAREHOUSE_ACCOUNT,
      variables: {
        organisationId,
        masterWarehouseAccountId,
      },
    });
  }
}
