import * as React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Card, Modal, Portal } from 'react-native-paper';
import { observer } from 'mobx-react';
import { useNavigation } from '@react-navigation/native';
import { Button } from '@ui-kitten/components';
import { useIsFocused } from '@react-navigation/core';
import { format } from 'date-fns';
import { all, sum } from 'ramda';
import * as Linking from 'expo-linking';
import CsvUploader from '../../components/CsvUploader';
import Table from '../../components/Table';
import Background from '../../components/Background';
import exportCsv from '../../core/csv';
import { UIStatusWrapper } from '../../components/ui-status';
import {
  generateNanoIdByCustomAlphabet,
  printBase64Pdf,
  unifiedAlert,
  unifiedConfirm,
} from '../../core/utils/utils';
import {
  CARRIER_ALLOW_PRINT_MANIFESTS,
  SHIPMENT_ORDER_STATES,
  SHIPMENT_ORDER_STATE_MAP,
  SHIPPING_CHANNEL_CODE,
} from '@ezom/library/lib/cjs/constants';
import {
  courierShipmentStore,
  courierShipmentTableStore,
  warehouseAccountStore,
} from '../../store';
import CourierShipmentStaging from '../../components/CourierShipmentStaging';
import { CourierShipmentStore } from '../../store/CourierShipmentStore';
import CourierShipmentListControl from '../../components/CourierShipmentListControl';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { useIsMobile } from '../../core/responsive.utils';
import CreateShipmentForm from '../../components/CreateShipmentForm';

const getId = (item) => item.id;
const selectedShipmentOrders = (shipmentOrders, selectedIds) =>
  shipmentOrders.filter((o) => selectedIds.has(getId(o)));
const CANCELLABLE_STATUS = [SHIPMENT_ORDER_STATES.Draft];
const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

const CourierShipmentBulkUploadModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit }) => {
    const [stagedShipmentOrders, setStagedShipmentOrders] = React.useState([]);
    const [shipmentOrderErrors, setShipmentOrderErrors] = React.useState({});
    const onUploadCSV = async (data) => {
      const mappedShipmentOrders = CourierShipmentStore.MapCsvUploadedShipmentOrders(data);
      setStagedShipmentOrders(mappedShipmentOrders);
      setShipmentOrderErrors(
        await courierShipmentStore.validateCsvUploadedShipmentOrders(
          courierShipmentTableStore.selectedWarehouseAccount?.id,
          mappedShipmentOrders,
        ),
      );
    };
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          {stagedShipmentOrders && stagedShipmentOrders.length == 0 ? (
            <>
              {/* Force to read the first sheet */}
              <CsvUploader onDismiss={onDismiss} onSubmit={onUploadCSV} sheetIndices={[0]} />
              <Button
                appearance="ghost"
                style={styles.button}
                onPress={() =>
                  Linking.openURL(
                    require('../../spreadsheetTemplates/CourierShipmentTemplate.xlsx'),
                  )
                }>
                Download Template
              </Button>
            </>
          ) : (
            <CourierShipmentStaging
              shipmentOrders={stagedShipmentOrders}
              onClearOrders={() => setStagedShipmentOrders([])}
              errors={shipmentOrderErrors}
              onSubmit={async () => {
                await onSubmit(stagedShipmentOrders);
                setStagedShipmentOrders([]);
              }}
            />
          )}
        </Modal>
      </Portal>
    );
  },
);

const CourierShipmentBulkBatchSubmitModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit }) => {
    const onUploadCSV = async (data) => {
      const mappedBatchSubmitShipmentOrderIds = data.map((o) => o['ID']);
      onSubmit(mappedBatchSubmitShipmentOrderIds);
    };
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          <>
            {/* Force to read the first sheet */}
            <CsvUploader onDismiss={onDismiss} onSubmit={onUploadCSV} sheetIndices={[0]} />
            <Button
              appearance="ghost"
              style={styles.button}
              onPress={() =>
                Linking.openURL(
                  require('../../spreadsheetTemplates/BatchSubmitCourierShipmentsTemplate.xlsx'),
                )
              }>
              Download Template
            </Button>
          </>
        </Modal>
      </Portal>
    );
  },
);

export default observer(() => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const isMobile = useIsMobile();
  const [duplicateCourierShipmentModal, setDuplicateCourierShipmentModal] = React.useState({
    data: null,
    isOpen: false,
  });

  const handleCloseDuplicateCourierShipmentModal = () => {
    setDuplicateCourierShipmentModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleOpenDuplicateCourierShipmentModal = (data) => {
    setDuplicateCourierShipmentModal(() => ({ isOpen: true, data }));
  };

  React.useEffect(() => {
    if (isFocused && courierShipmentTableStore.selectedWarehouseAccount) {
      courierShipmentTableStore.fetchItems();
    }
  }, [isFocused, courierShipmentTableStore.selectedWarehouseAccount]);

  // set selectedWarehouseAccount to null when unmounting, so users can select a new warehouse account when they come back
  React.useEffect(() => () => courierShipmentTableStore.setSelectedWarehouseAccount(null), []);

  return (
    <Background fullWidth={true}>
      <WarehouseAccountSelectionModal
        visible={!courierShipmentTableStore.selectedWarehouseAccount}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          courierShipmentTableStore.setSelectedWarehouseAccount(warehouseAccount);
        }}
        onCancel={() => navigation.navigate('DashboardScreen')}
      />
      <CourierShipmentListControl
        filterParams={courierShipmentTableStore.filterParams}
        indicators={getIndicators(courierShipmentTableStore.filterParams)}
        onSearchTermChange={courierShipmentTableStore.setSearchTerm}
        onFilterClear={() => {
          courierShipmentTableStore.resetFilterParams();
        }}
        onFilterChange={(params) => {
          courierShipmentTableStore.setFilterParams({
            ...courierShipmentTableStore.filterParams,
            ...params,
          });
        }}
        sortOptions={courierShipmentTableStore.sortOption}
        onSortChange={courierShipmentTableStore.setSortOption}
        disableSearch={
          courierShipmentTableStore.loading || !courierShipmentTableStore.selectedWarehouseAccount
        }
        requireButtonClickToSearch={courierShipmentTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: courierShipmentTableStore.error,
            indeterminate:
              courierShipmentTableStore.loading ||
              !courierShipmentTableStore.selectedWarehouseAccount,
          }}>
          <Table
            heightOffset={300}
            items={courierShipmentTableStore.items}
            getId={getId}
            displayKeys={courierShipmentTableStore.DISPLAY_KEYS}
            titleByKey={courierShipmentTableStore.TITLE_BY_KEY}
            formatterByKey={courierShipmentTableStore.FORMATTER_BY_KEY}
            rowOnClick={(item) => {
              navigation.navigate('ShipmentOrderDetail', {
                id: item.id,
                warehouseAccountId: courierShipmentTableStore.selectedWarehouseAccount?.id,
              });
            }}
            sort="single"
            isFabVisible={useIsFocused()}
            currentPage={courierShipmentTableStore.currentPage}
            onPageChange={(page) => {
              courierShipmentTableStore.setCurrentPage(page);
            }}
            totalItemNum={courierShipmentTableStore.total}
            itemsPerPage={courierShipmentTableStore.pageSize}
            setPageSize={courierShipmentTableStore.setPageSize}
            fabActions={(selectedIds) => {
              const actions = [];
              actions.push({
                icon: 'upload',
                label: 'Batch submit shipment order(s)',
                onPress: () => {
                  courierShipmentTableStore.toggleBatchSubmitShipmentModal(true);
                },
              });
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'plus',
                  label: 'Add shipment order',
                  onPress: () => {
                    navigation.navigate('NewShipment');
                  },
                });
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all',
                  onPress: () => {
                    const shipmentOrders = courierShipmentTableStore.items.map(
                      ({ lstsku, __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders =
                      shipmentOrders?.length > 0 ? Object.keys(shipmentOrders[0]) : [];
                    exportCsv(shipmentOrders, csvHeaders, 'Shipment orders.csv');
                  },
                });
                actions.push({
                  icon: 'file-upload-outline',
                  label: 'Upload in batch',
                  onPress: () => {
                    courierShipmentTableStore.openCsvModal();
                  },
                });
              }
              if (selectedIds.size === 1) {
                actions.push({
                  icon: 'content-copy',
                  label: `Duplicate selected order`,
                  onPress: () => {
                    handleOpenDuplicateCourierShipmentModal(
                      selectedShipmentOrders(courierShipmentTableStore.items, selectedIds)[0],
                    );
                  },
                });
              }
              if (selectedIds.size > 0) {
                if (
                  all(
                    (item) => CANCELLABLE_STATUS.includes(item.status),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'cancel',
                    label: `Cancel selected ${selectedIds.size > 1 ? 'orders' : 'order'}`,
                    onPress: () => {
                      unifiedConfirm(
                        'Are you sure you want to cancel the shipments?',
                        '',
                        async () => {
                          const res = await Promise.all(
                            [...selectedIds].map(async (id) => {
                              try {
                                await courierShipmentStore.cancelItem(
                                  courierShipmentTableStore.selectedWarehouseAccount?.id,
                                  id,
                                );
                                return { id };
                              } catch (e) {
                                return { error: e, id };
                              }
                            }),
                          );
                          const message = res
                            .map((r) =>
                              r.error
                                ? `Failed to cancel ${r.id}, ${r.error.message}`
                                : `${r.id} cancelled`,
                            )
                            .join('\n');
                          unifiedAlert(message);
                          courierShipmentTableStore.fetchItems();
                        },
                      );
                    },
                  });
                }
                if (
                  all(
                    (item) => [SHIPMENT_ORDER_STATES.Draft].includes(item.status),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'truck-check',
                    label: `Submit selected ${selectedIds.size > 1 ? 'orders' : 'order'}`,
                    onPress: () => {
                      unifiedConfirm(
                        'Are you sure you want to submit the following shipments?',
                        [...selectedIds].join('\n') +
                          '\nShipments cannot be cancelled after submission.',
                        async () => {
                          const res = await Promise.all(
                            [...selectedIds].map(async (id) => {
                              try {
                                const { createTransaction } = await courierShipmentStore.submitItem(
                                  courierShipmentTableStore.selectedWarehouseAccount?.id,
                                  id,
                                );
                                // wait for 200ms to avoid rate limit
                                await new Promise((res) => setTimeout(res, 200));
                                return { id, amount: createTransaction.amount };
                              } catch (e) {
                                return { error: e, id };
                              }
                            }),
                          );
                          let message = res
                            .map((r) =>
                              r.error
                                ? `Failed to submit ${r.id}, ${r.error.message}`
                                : `${r.id} submitted. \n`,
                            )
                            .join('\n');
                          const totalCharge = sum(res.map((r) => r.amount || 0));
                          message += ` Cost is $${totalCharge}.`;
                          unifiedAlert(message);
                          courierShipmentTableStore.fetchItems();
                        },
                      );
                    },
                  });
                }
                actions.push({
                  icon: 'download-outline',
                  label: `Export selected ${
                    selectedIds.size > 1 ? 'shipment orders' : 'shipment order'
                  }`,
                  onPress: () => {
                    const shipmentOrders = selectedShipmentOrders(
                      courierShipmentTableStore.items,
                      selectedIds,
                    );
                    const expoertedShipmentOrders = shipmentOrders.map(
                      ({ __typename, create_time, ...fields }) => ({
                        ...fields,
                        create_time: new Date(Number(create_time)).toLocaleString(),
                      }),
                    );
                    const csvHeaders =
                      expoertedShipmentOrders?.length > 0
                        ? Object.keys(expoertedShipmentOrders[0])
                        : [];
                    exportCsv(expoertedShipmentOrders, csvHeaders, 'Shipment orders.csv');
                  },
                });
                if (
                  all(
                    (item) =>
                      [SHIPMENT_ORDER_STATES.Draft, SHIPMENT_ORDER_STATES.Submitted].includes(
                        item.status,
                      ),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'printer',
                    label: `Print shipping labels for selected ${
                      selectedIds.size > 1 ? 'orders' : 'order'
                    }`,
                    onPress: async () => {
                      try {
                        const labels = await courierShipmentStore.printLabels(
                          courierShipmentTableStore.selectedWarehouseAccount?.id,
                          [...selectedIds],
                        );
                        for (let label of labels) {
                          printBase64Pdf(label);
                        }
                      } catch (e) {
                        unifiedAlert(e);
                      }
                    },
                  });
                }

                if (
                  all(
                    (item) => [SHIPMENT_ORDER_STATES.Submitted].includes(item.status),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  ) &&
                  all(
                    (item) =>
                      all(
                        (s) => CARRIER_ALLOW_PRINT_MANIFESTS.includes(s.logistics_provider),
                        item.shipments,
                      ),
                    selectedShipmentOrders(courierShipmentTableStore.items, selectedIds),
                  )
                ) {
                  actions.push({
                    icon: 'printer',
                    label: `Print manifests for selected ${
                      selectedIds.size > 1 ? 'orders' : 'order'
                    }`,
                    onPress: async () => {
                      try {
                        const labels = await courierShipmentStore.printManifests(
                          courierShipmentTableStore.selectedWarehouseAccount?.id,
                          [...selectedIds],
                        );
                        for (let label of labels) {
                          printBase64Pdf(label);
                        }
                      } catch (e) {
                        unifiedAlert(e);
                      }
                    },
                  });
                }
              }
              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>

      <CourierShipmentBulkUploadModal
        contentContainerStyle={styles.modalStyle}
        visible={courierShipmentTableStore.csvModalOpen}
        onDismiss={courierShipmentTableStore.closeCsvModal}
        onSubmit={async (courierShipments) => {
          const results = await Promise.allSettled(
            courierShipments.map(async ({ shipment, ...s }, index) => {
              // wait for 2s to avoid rate limit
              await new Promise((res) => setTimeout(res, 2000 * index));
              await courierShipmentTableStore.dataStore.addItem(
                courierShipmentTableStore.selectedWarehouseAccount?.id,
                {
                  shipments: [shipment],
                  ...s,
                },
              );
            }),
          );
          unifiedAlert(
            results
              .map((r, i) => ({
                message: `Shipment ${i + 1}: ${r.status === 'rejected' ? r.reason : 'created.'}`,
                ...r,
              }))
              .map((r) => r.message)
              .join('\n'),
          );
          // Refetch ICs after 2 second
          setTimeout(() => courierShipmentTableStore.fetchItems(), 500);
          courierShipmentTableStore.closeCsvModal();
        }}
      />

      <CourierShipmentBulkBatchSubmitModal
        contentContainerStyle={styles.modalStyle}
        visible={courierShipmentTableStore.batchSubmitShipmentOpen}
        onDismiss={() => courierShipmentTableStore.toggleBatchSubmitShipmentModal(false)}
        onSubmit={async (courierShipmentIds) => {
          unifiedConfirm(
            `Are you sure you want to submit the shipments with: ` + courierShipmentIds.join('\n'),
            '\nShipments cannot be cancelled after submission.',
            async () => {
              const res = [];
              for (let i = 0; i < courierShipmentIds.length; i++) {
                const id = courierShipmentIds[i];
                try {
                  const { createTransaction } = await courierShipmentStore.submitItem(
                    courierShipmentTableStore.selectedWarehouseAccount?.id,
                    id,
                  );
                  await new Promise((resolve) => setTimeout(resolve, 1000));
                  res.push({ id, amount: createTransaction.amount });
                } catch (e) {
                  res.push({ error: e, id });
                }
              }

              let message = res
                .map((r) =>
                  r.error ? `Failed to submit ${r.id}, ${r.error.message}` : `${r.id} submitted.`,
                )
                .join('\n');
              const totalCharge = sum(res.map((r) => r.amount || 0));
              message += ` Cost is $${totalCharge}.`;

              unifiedAlert(message);
              courierShipmentTableStore.fetchItems();
            },
          );
          courierShipmentTableStore.toggleBatchSubmitShipmentModal(false);
        }}
      />

      <Portal>
        <Modal
          visible={duplicateCourierShipmentModal.isOpen}
          onDismiss={() => handleCloseDuplicateCourierShipmentModal()}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}>
          <ScrollView>
            <CreateShipmentForm
              onSuccess={() => {
                handleCloseDuplicateCourierShipmentModal();
                courierShipmentTableStore.fetchItems();
              }}
              data={{
                ...duplicateCourierShipmentModal.data,
                ref_no:
                  duplicateCourierShipmentModal.data?.ref_no +
                  '_' +
                  generateNanoIdByCustomAlphabet(),
              }}
              warehouseAccountId={courierShipmentTableStore.selectedWarehouseAccount?.id}
            />
          </ScrollView>
        </Modal>
      </Portal>
    </Background>
  );
});

const styles = StyleSheet.create({
  modalStyle: {
    backgroundColor: 'white',
    margin: 20,
    alignSelf: 'center',
    minWidth: 500,
  },
  dialogueStyle: {
    alignSelf: 'center',
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },

  mobileModalStyle: { backgroundColor: 'white', margin: 20, padding: 10 },
  modalStyle: {
    maxHeight: '95vh',
    backgroundColor: 'white',
    marginHorizontal: 'auto',
    width: '90%',
    maxWidth: 1680,
    padding: 10,
    margin: 40,
  },
});

const getIndicators = (params) => {
  const dateFormatter = (date) => format(date, 'd MMM y');
  let indicators = [];

  if (params.consignmentNumber) {
    indicators.push(`consignment no.: ${params.consignmentNumber}`);
  } else if (params.refNumber) {
    indicators.push(`ref no.: ${params.refNumber}`);
  } else if (params.fourpxTrackingNumber) {
    indicators.push(`ref no.: ${params.ref_no}`);
  } else if (params.shippingNumber) {
    indicators.push(`tracking no.: ${params.shippingNumber}`);
  } else {
    if (params.status) {
      indicators.push(`status: ${SHIPMENT_ORDER_STATE_MAP[params.status]}`);
    }
    if (params.sku_code) {
      indicators.push(`SKu: ${params.sku_code}`);
    }

    if (params.create_time_start && params.create_time_end) {
      indicators.push(
        `orders from ${dateFormatter(params.create_time_start)} to ${dateFormatter(
          params.create_time_end,
        )}`,
      );
    } else if (params.create_time_start) {
      indicators.push(`orders from ${dateFormatter(params.create_time_start)}`);
    } else if (params.create_time_end) {
      indicators.push(`orders by ${dateFormatter(params.create_time_end)}`);
    }
  }

  return indicators;
};
