import { Button, Divider, Icon, Layout, Text } from '@ui-kitten/components';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { isNil } from 'ramda';
import React, { memo, useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { Card } from 'react-native-paper';
import { paperNativeTheme } from 'src/core/theme';
import * as Yup from 'yup';

import { SelectAddressBookModal } from './AddressBook/SelectAddressBookModal';
import Autocomplete from './Autocomplete';
import { useIsMobile } from '../core/responsive.utils';
import { TextInputField } from './input/TextInputField';
import {
  addressBookStore,
  countryStateCityCurrencyStore,
  courierShipmentStore,
  outboundOrderTableStore,
} from '../store';
import { ConfirmDialog } from './ConfirmDialog';
import { ERROR_SHIPING_COST_FIELD, ShipmentInfo } from './ShipmentInfoForm';
import { unifiedAlert } from '../core/utils/utils';
import { prepairAddressData } from '@ezom/library/lib/cjs/shippingCost';
import { SaveNewAddressBookModal } from './AddressBook/SaveNewModal';
import { AU_POSTCODE_LENGTH } from '@ezom/library/lib/cjs/constants/constants';

const windowHeight = Dimensions.get('window').height;

const ADDRESS_TYPE = {
  from: 'from',
  to: 'to',
};

const ACTION_TYPE = {
  add: 'add',
  delete: 'delete',
  update: 'update',
};

// checks if export info is required for the chose courier
const exportInfoTester = (courierList) => (value, testContext) => {
  const courierConfig = courierList.find(
    (courier) => courier.logistics_product_code === testContext.from[2]?.value?.courier,
  );
  if (courierConfig?.requireExportInfo) {
    return !!value;
  }
  return true;
};

const INIT_SHIPMENT_ITEM = {
  exportInfo: [
    {
      declare: '',
      hscode: '',
      qty: '',
      weight: '',
      declareValue: '',
      countryOfOrigin: '',
    },
  ],
  weight: '',
  height: '',
  width: '',
  length: '',
  qty: '',
  description: '',
  commodity: '',
};

const INIT_SHIPMENT_VALUE = {
  sales_no: '',
  ref_no: '',
  note: '',
  shipment: {
    courier: '',
    deliveryWindowEnabled: false,
    deliveryWindowStart: '',
    deliveryWindowEnd: '',
    dangerousGoods: false,
    handUnload: false,
    tailgate: false,
    items: [INIT_SHIPMENT_ITEM],
  },
  pickupAddress: {
    country: 'AU',
    name: '',
    state: '',
    city: '',
    phone: '',
    email: '',
    post_code: '',
    company: '',
    street: '',
  },
  receiverAddress: {
    country: 'AU',
    name: '',
    state: '',
    city: '',
    phone: '',
    email: '',
    post_code: '',
    company: '',
    street: '',
  },
};

export const getShipmentValidationSchema = (courierList) =>
  Yup.object({
    ref_no: Yup.string().required('Required').max(25),
    sales_no: Yup.string(),
    note: Yup.string().nullable().max(250),
    shipment: Yup.object({
      courier: Yup.string().required('Required'),
      deliveryWindowEnabled: Yup.boolean().required(),
      deliveryWindowStart: Yup.string().when('deliveryWindowEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      deliveryWindowEnd: Yup.string().when('deliveryWindowEnabled', {
        is: true,
        then: Yup.string().required('Required'),
      }),
      dangerousGoods: Yup.boolean().required(),
      handUnload: Yup.boolean().required(),
      tailgate: Yup.boolean().required(),
      items: Yup.array()
        .min(1)
        .of(
          Yup.object({
            description: Yup.string().required('Required'),
            commodity: Yup.string(),
            qty: Yup.number().integer('Integer only').min(1, 'Too small').required('Required'),
            weight: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            length: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            width: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            height: Yup.number()
              .min(0.1, 'Too small')
              .max(Number.MAX_SAFE_INTEGER, 'Too large')
              .required('Required'),
            exportInfo: Yup.array().of(
              Yup.object({
                declare: Yup.string().test('declare', 'Required', exportInfoTester(courierList)),
                hscode: Yup.string().test(
                  'declareHscode',
                  'Required',
                  exportInfoTester(courierList),
                ),
                qty: Yup.number()
                  .integer('Integer only')
                  .min(1, 'Too small')
                  .max(Number.MAX_SAFE_INTEGER, 'Too large')
                  .test('declareQty', 'Required', exportInfoTester),
                declareValue: Yup.number()
                  .min(0, 'Too small')
                  .max(Number.MAX_SAFE_INTEGER, 'Too large')
                  .test('declareValue', 'Required', exportInfoTester),
                countryOfOrigin: Yup.string()
                  .length(2, 'Country of origin must be a 2-character code')
                  .test('countryOfOrigin', 'Required', exportInfoTester),
                weight: Yup.number()
                  .min(0.01, 'Too small')
                  .max(Number.MAX_SAFE_INTEGER, 'Too large')
                  .test('declareWeight', 'Required', exportInfoTester),
              }),
            ),
          }),
        ),
    }).required(),
    pickupAddress: Yup.object({
      country: Yup.string().required().max(50),
      name: Yup.string().required().max(35),
      state: Yup.string().required().max(50),
      city: Yup.string().required().max(50),
      phone: Yup.string().max(20),
      email: Yup.string().email('Not a valid email address').max(50),
      post_code: Yup.string()
        .required()
        .when('country', (country, schema) => {
          if (country === 'AU') {
            return schema.length(
              AU_POSTCODE_LENGTH,
              `In ${country}, the postcode must be ${AU_POSTCODE_LENGTH} characters long`,
            );
          }
          return schema.max(12);
        }),
      company: Yup.string().nullable().max(30),
      street: Yup.string().required().max(60),
    }),
    receiverAddress: Yup.object({
      country: Yup.string().required().max(50),
      name: Yup.string().required().max(35),
      state: Yup.string().required().max(50),
      city: Yup.string().required().max(50),
      phone: Yup.string().max(20),
      email: Yup.string().email('Not a valid email address').max(50),
      post_code: Yup.string()
        .required()
        .when('country', (country, schema) => {
          if (country === 'AU') {
            return schema.length(
              AU_POSTCODE_LENGTH,
              `In ${country}, the postcode must be ${AU_POSTCODE_LENGTH} characters long`,
            );
          }
          return schema.max(12);
        }),
      company: Yup.string().nullable().max(30),
      street: Yup.string().required().max(60),
    }),
  });

const CreateShipmentForm = observer(
  ({ data: courierData = null, warehouseAccountId, onSuccess }) => {
    const isMobile = useIsMobile();
    const COUNTRIES = countryStateCityCurrencyStore.countryCodes;

    const initialValues = courierData
      ? {
          sales_no: courierData.sales_no,
          ref_no: courierData.ref_no,
          note: courierData.note,
          shipment: {
            courier: courierData.shipments[0].courier,
            deliveryWindowEnabled: false,
            deliveryWindowStart: '',
            deliveryWindowEnd: '',
            dangerousGoods: false,
            handUnload: false,
            tailgate: false,
            items: JSON.parse(JSON.stringify(courierData.shipments[0].items)).map((item) => ({
              length: item.length,
              weight: item.weight / 1000,
              width: item.width,
              height: item.height,
              qty: item.qty,
              description: item?.description || ' ',
              commodity: item?.commodity,
            })),
          },
          pickupAddress: {
            country: courierData.pickup_country,
            name: courierData.pickup_name,
            state: courierData.pickup_state,
            city: courierData.pickup_city,
            phone: courierData.pickup_phone,
            email: courierData.pickup_email || '',
            post_code: courierData.pickup_post_code,
            company: courierData.pickup_company,
            street: courierData.pickup_street,
          },
          receiverAddress: {
            country: courierData.receiver_country,
            name: courierData.receiver_name,
            state: courierData.receiver_state,
            city: courierData.receiver_city,
            phone: courierData.receiver_phone,
            email: courierData.receiver_email,
            post_code: courierData.receiver_post_code,
            company: courierData.receiver_company,
            street: courierData.receiver_street,
          },
        }
      : INIT_SHIPMENT_VALUE;
    const courierList = outboundOrderTableStore.couriers?.[warehouseAccountId] || [];

    const [selectAddressBookVisible, setSelectAddressBookVisible] = useState(false);
    const [saveNewAddressBookVisible, setSaveNewAddressBookVisible] = useState(false);

    return (
      <Formik
        isInitialValid={false}
        initialValues={initialValues}
        validationSchema={getShipmentValidationSchema(courierList)}
        onSubmit={async (values, formikActions) => {
          formikActions.setSubmitting(true);

          const noItemsFound = values.shipment.items.length === 0;

          if (noItemsFound) {
            unifiedAlert(`Please add items to the Shipment`);
          } else if (values[ERROR_SHIPING_COST_FIELD]) {
            unifiedAlert(values[ERROR_SHIPING_COST_FIELD]);
          } else {
            try {
              const { id } = await courierShipmentStore.addItem(warehouseAccountId, {
                ref_no: values.ref_no,
                sales_no: values.sales_no,
                note: values.note,
                pickupAddress: prepairAddressData(values.pickupAddress),
                receiverAddress: prepairAddressData(values.receiverAddress),
                shipments: [
                  {
                    ...values.shipment,
                    items: values.shipment.items.map(
                      ({ exportInfo, weight, length, width, height, qty, ...item }) => ({
                        exportInfo: courierList.find(
                          (courier) => courier.logistics_product_code === values.shipment.courier,
                        )?.requireExportInfo
                          ? exportInfo.map((info) => ({
                              ...info,
                              weight: parseFloat(info.weight) * 1000,
                              qty: parseInt(info.qty),
                              declareValue: parseFloat(info.declareValue),
                            }))
                          : undefined,
                        weight: parseFloat(weight) * 1000,
                        length: parseFloat(length),
                        height: parseFloat(height),
                        width: parseFloat(width),
                        height: parseFloat(height),
                        qty: parseInt(qty),
                        ...item,
                      }),
                    ),
                  },
                ],
              });

              unifiedAlert(`Shipment order: ${id}.`);
              onSuccess();
            } catch (e) {
              console.error(e);
              unifiedAlert(e);
            }
          }

          formikActions.setSubmitting(false);
        }}>
        {(props) => {
          const [senderStates, setSenderStates] = useState([]);
          const [receiverStates, setReceiverStates] = useState([]);
          useEffect(() => {
            (async () => {
              if (props.values.pickupAddress?.country) {
                const states = await countryStateCityCurrencyStore.getStateCodesOfCountry(
                  props.values.pickupAddress.country,
                );
                setSenderStates(states);
              }
            })();
          }, [props.values.pickupAddress.country]);
          useEffect(() => {
            (async () => {
              if (props.values.receiverAddress?.country) {
                const states = await countryStateCityCurrencyStore.getStateCodesOfCountry(
                  props.values.receiverAddress.country,
                );
                setReceiverStates(states);
              }
            })();
          }, [props.values.receiverAddress.country]);

          const [senderCities, setSenderCities] = useState([]);
          const [receiverCities, setReceiverCities] = useState([]);
          useEffect(() => {
            (async () => {
              if (props.values.pickupAddress?.country && props.values.pickupAddress?.state) {
                const cities = await countryStateCityCurrencyStore.getCitiesOfState(
                  props.values.pickupAddress.country,
                  props.values.pickupAddress.state,
                );
                setSenderCities(cities);
              }
            })();
          }, [props.values.pickupAddress?.country, props.values.pickupAddress?.state]);
          useEffect(() => {
            (async () => {
              if (props.values.receiverAddress?.country && props.values.receiverAddress?.state) {
                const cities = await countryStateCityCurrencyStore.getCitiesOfState(
                  props.values.receiverAddress.country,
                  props.values.receiverAddress.state,
                );
                setReceiverCities(cities);
              }
            })();
          }, [props.values.receiverAddress?.country, props.values.receiverAddress?.state]);

          const [sendingType, setSendingType] = useState('');

          const getAddressPropertyName = (type) => {
            return type === ADDRESS_TYPE.from ? 'pickupAddress' : 'receiverAddress';
          };

          const saveAddressBook = async (sendingType, actionType, code = '') => {
            try {
              const saveData = JSON.parse(
                JSON.stringify(props.values[getAddressPropertyName(sendingType)]),
              );
              //mapping data field because addressBook and shipment difference field (stree and address)
              saveData.address = saveData.street;
              delete saveData.street;
              if (actionType === ACTION_TYPE.add) {
                delete saveData.id;
                saveData.code = code;
              }
              const { id } = await addressBookStore.saveData(saveData);
              props.setFieldValue(`${getAddressPropertyName(sendingType)}.id`, id);
              setSaveNewAddressBookVisible(false);
            } catch (error) {
              unifiedAlert(error.message);
            }
          };

          const deleteAddressBook = async () => {
            await addressBookStore.deleteData([
              props.values[getAddressPropertyName(sendingType)].id,
            ]);
            props.setFieldValue(`${getAddressPropertyName(sendingType)}.id`, undefined);
          };

          const [showConfirmDelete, setShowConfirmDelete] = useState(false);
          const [actionType, setActionType] = useState('');

          const renderConfirmDelete = () => {
            return (
              <ConfirmDialog
                title={`Are you sure to ${actionType}?`}
                cancelText={'No, cancel'}
                confirmButtonStatus={'danger'}
                confirmText={`Yes, ${actionType} it`}
                message={'Deleted address book cannot be recovered'}
                onCancel={() => setShowConfirmDelete(false)}
                onConfirm={async () => {
                  await deleteAddressBook();
                  setShowConfirmDelete(false);
                }}
                onDismiss={() => {
                  setShowConfirmDelete(false);
                }}
                visible={showConfirmDelete}
              />
            );
          };

          const renderSendingHeader = (type, data) => {
            return (
              <Layout
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                }}>
                <Layout
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    flexWrap: 'wrap',
                    paddingBottom: 20,
                  }}>
                  <Card.Title title={`Sending ${type}`}></Card.Title>
                  <Layout
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      maxWidth: '100%',
                      borderRadius: 5,
                      padding: 5,
                      borderBottomColor: '#edf1f7',
                      borderBottomWidth: 1,
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                      borderLeftColor: '#edf1f7',
                      borderRightColor: '#edf1f7',
                    }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 10 }}>{`Address Book${
                      data.id ? ` (${data.id})` : ''
                    }`}</Text>
                    <Layout
                      style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                      <Button
                        size="tiny"
                        appearance="ghost"
                        onPress={() => {
                          const actionType = ACTION_TYPE.add;
                          setSendingType(type);
                          setActionType(actionType);
                          setSaveNewAddressBookVisible(true);
                        }}
                        accessoryLeft={(innerProps) => <Icon {...innerProps} name="plus" />}>
                        Save new
                      </Button>
                      {data.id && (
                        <Button
                          size="tiny"
                          appearance="ghost"
                          onPress={() => {
                            const actionType = ACTION_TYPE.update;
                            setSendingType(type);
                            setActionType(actionType);
                            saveAddressBook(type, actionType);
                          }}
                          accessoryLeft={(innerProps) => <Icon {...innerProps} name="edit" />}>
                          Update
                        </Button>
                      )}
                      <Button
                        size="tiny"
                        appearance="ghost"
                        onPress={() => {
                          setSendingType(type);
                          openSelectAddressBook();
                        }}
                        accessoryLeft={(innerProps) => <Icon {...innerProps} name="book" />}>
                        Lookup
                      </Button>
                      {data.id && (
                        <Button
                          size="tiny"
                          appearance="ghost"
                          onPress={() => {
                            setSendingType(type);
                            setShowConfirmDelete(true);
                            setActionType(ACTION_TYPE.delete);
                          }}
                          accessoryLeft={(innerProps) => <Icon {...innerProps} name="trash" />}>
                          Delete
                        </Button>
                      )}
                    </Layout>
                  </Layout>
                </Layout>
              </Layout>
            );
          };

          const selectAddressBook = (data) => {
            const dataAddress = { ...data };
            dataAddress.code = data.code ? data.code : '';
            dataAddress.street = data.address;
            props.setFieldValue(getAddressPropertyName(sendingType), dataAddress);
            closeSelectAddressBook();
          };

          const closeSelectAddressBook = () => {
            setSelectAddressBookVisible(false);
          };
          const openSelectAddressBook = () => {
            setSelectAddressBookVisible(true);
          };

          return (
            <>
              {renderConfirmDelete()}
              <SaveNewAddressBookModal
                visible={saveNewAddressBookVisible}
                onClose={() => {
                  setSaveNewAddressBookVisible(false);
                }}
                onSubmit={async (data) => {
                  props.setFieldValue(`${getAddressPropertyName(sendingType)}.code`, data.code);
                  await saveAddressBook(sendingType, actionType, data.code);
                }}
              />
              <SelectAddressBookModal
                visible={selectAddressBookVisible}
                onSelect={selectAddressBook}
                onClose={closeSelectAddressBook}
              />
              <Layout
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }}>
                <Card
                  style={{
                    width: 'calc((100% - 60px)/2)',
                  }}>
                  {renderSendingHeader(ADDRESS_TYPE.from, props.values.pickupAddress)}
                  <Card.Content>
                    {props.values.pickupAddress.id && (
                      <Autocomplete
                        label="Code"
                        name="pickupAddress.code"
                        style={styles.field}
                        value={props.values.pickupAddress.code}
                        onSelect={props.handleChange('pickupAddress.code')}
                        allowFreetext={true}
                        {...props}
                      />
                    )}
                    <Autocomplete
                      label="Sender country"
                      value={props.values.pickupAddress.country}
                      error={props.errors.pickupAddress?.country}
                      touched={props.touched.pickupAddress?.country}
                      style={styles.field}
                      placeholder="Select a country"
                      options={COUNTRIES}
                      onSelect={props.handleChange('pickupAddress.country')}
                      onBlur={props.handleBlur('pickupAddress.country')}
                    />
                    <Autocomplete
                      label="Sender state"
                      value={props.values.pickupAddress.state}
                      error={props.errors.pickupAddress?.state}
                      touched={props.touched.pickupAddress?.state}
                      style={styles.field}
                      placeholder="Select a state"
                      options={senderStates}
                      onSelect={props.handleChange('pickupAddress.state')}
                      onBlur={props.handleBlur('pickupAddress.state')}
                    />
                    <Autocomplete
                      label="Sender city / suburb"
                      value={props.values.pickupAddress.city}
                      error={props.errors.pickupAddress?.city}
                      touched={props.touched.pickupAddress?.city}
                      placeholder="Select a city / suburb"
                      style={styles.field}
                      disabled={!props.values.pickupAddress.state}
                      options={senderCities}
                      onSelect={props.handleChange('pickupAddress.city')}
                      onBlur={props.handleBlur('pickupAddress.city')}
                      allowFreetext={true}
                    />
                    <TextInputField
                      name="pickupAddress.name"
                      style={styles.field}
                      label="Sender name"
                      {...props}
                    />
                    <TextInputField
                      label="Sendre phone"
                      name="pickupAddress.phone"
                      style={styles.field}
                      textContentType="telephoneNumber"
                      keyboardType="phone-pad"
                      {...props}
                    />
                    <TextInputField
                      label="Sender email"
                      name="pickupAddress.email"
                      style={styles.field}
                      textContentType="emailAddress"
                      keyboardType="email-address"
                      {...props}
                    />
                    <TextInputField
                      label="Sender post code"
                      name="pickupAddress.post_code"
                      style={styles.field}
                      textContentType="postalCode"
                      keyboardType="number-pad"
                      {...props}
                    />
                    <TextInputField
                      label="Sender company"
                      name="pickupAddress.company"
                      style={styles.field}
                      {...props}
                    />
                    <TextInputField
                      label="Sender address"
                      name="pickupAddress.street"
                      style={styles.field}
                      {...props}
                    />
                  </Card.Content>
                </Card>
                <View
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: 'rgb(237, 241, 247)',
                    borderRightColor: 'rgb(237, 241, 247)',
                    marginVertical: 20,
                    borderRightWidth: 1,
                    justifyContent: 'center',
                  }}>
                  <Button
                    status="primary"
                    size="giant"
                    appearance="ghost"
                    style={{ paddingHorizontal: 0 }}
                    accessoryLeft={(props) => <Icon {...props} name="swap-outline" />}
                    onPress={() => {
                      const tmp = props.values.pickupAddress;
                      props.setFieldValue('pickupAddress', props.values.receiverAddress);
                      props.setFieldValue('receiverAddress', tmp);
                    }}
                  />
                </View>
                <Card
                  style={{
                    width: 'calc((100% - 60px)/2)',
                  }}>
                  {renderSendingHeader(ADDRESS_TYPE.to, props.values.receiverAddress)}
                  <Card.Content>
                    {props.values.receiverAddress.id && (
                      <Autocomplete
                        label="Code"
                        name="receiverAddress.code"
                        style={styles.field}
                        value={props.values.receiverAddress.code}
                        onSelect={props.handleChange('receiverAddress.code')}
                        allowFreetext={true}
                        {...props}
                      />
                    )}
                    <Autocomplete
                      label="Receiver country"
                      value={props.values.receiverAddress.country}
                      error={props.errors.receiverAddress?.country}
                      touched={props.touched.receiverAddress?.country}
                      style={styles.field}
                      placeholder="Select a country"
                      options={COUNTRIES}
                      onSelect={props.handleChange('receiverAddress.country')}
                      onBlur={props.handleBlur('receiverAddress.country')}
                    />
                    <Autocomplete
                      label="Receiver state"
                      value={props.values.receiverAddress.state}
                      error={props.errors.receiverAddress?.state}
                      touched={props.touched.receiverAddress?.state}
                      style={styles.field}
                      placeholder="Select a state"
                      options={receiverStates}
                      onSelect={props.handleChange('receiverAddress.state')}
                      onBlur={props.handleBlur('receiverAddress.state')}
                    />

                    <Autocomplete
                      label="Receiver city / suburb"
                      value={props.values.receiverAddress.city}
                      error={props.errors.receiverAddress?.city}
                      touched={props.touched.receiverAddress?.city}
                      placeholder="Select a city / suburb"
                      style={styles.field}
                      disabled={!props.values.receiverAddress.state}
                      options={receiverCities}
                      onSelect={props.handleChange('receiverAddress.city')}
                      onBlur={props.handleBlur('receiverAddress.city')}
                      allowFreetext={true}
                    />
                    <TextInputField
                      name="receiverAddress.name"
                      style={styles.field}
                      label="Receiver name"
                      {...props}
                    />
                    <TextInputField
                      label="Receiver phone"
                      name="receiverAddress.phone"
                      style={styles.field}
                      textContentType="telephoneNumber"
                      keyboardType="phone-pad"
                      {...props}
                    />
                    <TextInputField
                      label="Receiver email"
                      name="receiverAddress.email"
                      style={styles.field}
                      textContentType="emailAddress"
                      keyboardType="email-address"
                      {...props}
                    />
                    <TextInputField
                      label="Receiver post code"
                      name="receiverAddress.post_code"
                      style={styles.field}
                      textContentType="postalCode"
                      keyboardType="number-pad"
                      {...props}
                    />
                    <TextInputField
                      label="Receiver company"
                      name="receiverAddress.company"
                      style={styles.field}
                      {...props}
                    />
                    <TextInputField
                      label="Receiver address"
                      name="receiverAddress.street"
                      style={styles.field}
                      {...props}
                    />
                  </Card.Content>
                </Card>
              </Layout>
              <Divider />
              {warehouseAccountId ? (
                <ShipmentInfo
                  warehouseAccountId={warehouseAccountId}
                  touchedItems={props.touched.shipment?.items}
                  errorsItems={props.errors.shipment?.items}
                  fieldNamePrefix="shipment"
                  shipment={props.values.shipment}
                  {...props}
                />
              ) : null}
              <Divider />
              <Layout
                style={isMobile ? styles.mobileButtonContainer : styles.desktopButtonContainer}>
                <Button
                  status="primary"
                  loading={props.isSubmitting}
                  onPress={props.handleSubmit}
                  disabled={
                    props.isSubmitting ||
                    !!Object.keys(props.errors)?.length ||
                    !isNil(props.values[ERROR_SHIPING_COST_FIELD])
                  }
                  style={styles.button}>
                  Submit
                </Button>
                <Button
                  status="danger"
                  appearance="ghost"
                  disabled={props.isSubmitting}
                  onPress={props.handleReset}
                  style={styles.button}>
                  Reset
                </Button>
              </Layout>
              {/* <FAB.Group
            visible={true}
            fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
            open={fabOpen}
            icon={fabOpen ? 'chevron-down' : 'chevron-up'}
            actions={[
              {
                icon: 'printer',
                label: 'Print',
                onPress: () => setLabelPrintModalVisible(true),
              },
            ]}
            onStateChange={({ open }) => setFabOpen(open)}
          /> */}
            </>
          );
        }}
      </Formik>
    );
  },
);

export default memo(CreateShipmentForm);

export const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  formContainer: {
    height: windowHeight - 80,
    overflowY: 'auto',
    borderColor: paperNativeTheme.colors.secondary,
    borderWidth: '0.5px',
    borderRadius: '4px',
    padding: '20px',
  },
});
