import { Button, ButtonGroup, Icon, Layout } from '@ui-kitten/components';
import { Formik } from 'formik';
import { StyleSheet } from 'react-native';
import { Divider, Modal, Portal } from 'react-native-paper';
import { ezTheme } from '../../core/theme';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { TextInputField } from '../input/TextInputField';

export const SaveNewAddressBookModal = ({ onSubmit = () => {}, onClose, visible }) => {
  const [data, setData] = useState({ code: '' });

  const renderFrom = () => {
    return (
      <Formik
        initialValues={data}
        enableReinitialize
        va
        validationSchema={Yup.object({
          code: Yup.string().required(),
        })}
        onSubmit={(value, formikActions) => {
          onSubmit(value);
          formikActions.setSubmitting(false);
        }}>
        {(props) => {
          return (
            <Layout style={styles.formContainer}>
              <TextInputField label="Code (Nickname)" name="code" style={styles.field} {...props} />
              <Divider />
              <ButtonGroup appearance="ghost" style={styles.btnContainer}>
                <Button
                  appearance="outline"
                  status="primary"
                  accessoryLeft={(props) => <Icon {...props} name="save-outline" />}
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting || !props.isValid || !props.dirty}
                  onPress={props.handleSubmit}>
                  Save
                </Button>
                <Button
                  appearance="outline"
                  status="danger"
                  accessoryLeft={(innerProps) => (
                    <Icon {...innerProps} name="close-circle-outline" />
                  )}
                  loading={props.isSubmitting}
                  disabled={props.isSubmitting}
                  onPress={onClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Layout>
          );
        }}
      </Formik>
    );
  };

  return (
    <Portal>
      <Modal
        style={styles.container}
        visible={visible}
        onDismiss={onClose}
        dismissable={true}
        contentContainerStyle={styles.modalContent}>
        {renderFrom()}
      </Modal>
    </Portal>
  );
};
const styles = StyleSheet.create({
  container: {},
  backdropStyle: {
    backgroundColor: ezTheme.backdropModalColor,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 5,
    marginHorizontal: 'auto',
  },
  field: {
    marginVertical: 8,
  },
  btnContainer: {
    marginHorizontal: '5px',
    justifyContent: 'space-evenly',
    marginVertical: 8,
  },
  formContainer: {
    alignSelf: 'center',
    overflowY: 'auto',
  },
});
