import React, { useState } from 'react';
import { Picker } from '@react-native-picker/picker';
import { StyleSheet, View, TouchableOpacity, TextInput } from 'react-native';
import { paperNativeTheme, ezTheme } from '../core/theme';
import { Icon, Text } from '@ui-kitten/components';

/**
 * CustomPagination component for handling pagination UI.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {number} props.totalPages - The total number of pages.
 * @param {number} props.currentPage - The current active page.
 * @param {Function} props.onPageChange - The function to handle page changes.
 * @param {number} props.total - The total number of items.
 * @param {number} props.itemsPerPage - The current number of items per page.
 * @param {Function} props.onItemsPerPageChange - The function to handle items per page changes.
 * @returns {JSX.Element} The rendered CustomPagination component.
 */

const EzPagination = ({
  totalPages,
  currentPage,
  onPageChange,
  total,
  itemsPerPageOptions,
  itemsPerPage,
  onItemsPerPageChange,
  selectedItemsPerPage,
  displayRange = 3,
}) => {
  const BASE_ITEMS_PER_PAGE = 20;
  const MAX_ITEMS_PER_PAGE = Math.min(400, total);

  const ITEMS_PER_PAGE_OPTIONS = [BASE_ITEMS_PER_PAGE];
  let multiplier = 2;

  while (multiplier * BASE_ITEMS_PER_PAGE <= MAX_ITEMS_PER_PAGE) {
    ITEMS_PER_PAGE_OPTIONS.push(multiplier * BASE_ITEMS_PER_PAGE);
    multiplier *= 2;
  }

  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  const [goToPage, setGoToPage] = useState(`${currentPage}`);
  const [error, setError] = useState('');
  const [previousGoToPage, setPreviousGoToPage] = useState(`${currentPage}`);

  const previousButtonDisabled = currentPage === 1;
  const nextButtonDisabled = currentPage === totalPages;

  const handleGoToPage = (pageNumber) => {
    const newPage = parseInt(pageNumber, 10);
    if (pageNumber.trim() === '') {
      setError('');
    } else if (isNaN(newPage)) {
      setError('Please enter a valid number');
    } else if (newPage < 1) {
      setError('The page number must be positive');
    } else if (newPage > totalPages) {
      setError(`The page number must be no more than ${totalPages}`);
    } else {
      onPageChange(newPage);
      setError('');
    }
  };

  const onBlurText = () => {
    if (goToPage.trim() === '') {
      setGoToPage(previousGoToPage);
    } else if (goToPage !== previousGoToPage) {
      handleGoToPage(goToPage);
    }
  };

  const getPageDisplay = () => {
    if (totalPages <= displayRange) {
      return pageNumbers;
    }

    const halfRange = Math.floor(displayRange / 2);
    let start, end;

    if (currentPage <= halfRange) {
      start = 1;
      end = displayRange;
    } else if (currentPage >= totalPages - halfRange) {
      start = totalPages - displayRange + 1;
      end = totalPages;
    } else {
      start = currentPage - halfRange;
      end = currentPage + halfRange;
    }

    let displayedPages = [];
    if (start > 1) {
      displayedPages.push(1);
      if (start > 2) {
        displayedPages.push('...');
      }
    }

    for (let i = start; i <= end; i++) {
      displayedPages.push(i);
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        displayedPages.push('...');
      }
      displayedPages.push(totalPages);
    }

    return displayedPages;
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{`${total} ${total > 1 ? 'items' : 'item'}`}</Text>

      <Text style={[styles.text, { marginLeft: 20 }]}>Items per page:</Text>
      <Picker
        selectedValue={itemsPerPage}
        onValueChange={(itemValue, itemIndex) => onItemsPerPageChange(Number(itemValue))}>
        {ITEMS_PER_PAGE_OPTIONS.map((value, index) => (
          <Picker.Item key={index} label={`${value}`} value={value} />
        ))}
      </Picker>

      <View style={styles.paginationContainer}>
        <TouchableOpacity
          style={[
            styles.button,
            {
              opacity: previousButtonDisabled ? 0.5 : 1,
              marginRight: 4,
              padding: 4,
              flexDirection: 'row',
            },
          ]}
          onPress={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}>
          <Icon name="chevron-left" style={{ width: 20, height: 20 }} />
          <Text style={{ marginRight: 4 }}>Previous</Text>
        </TouchableOpacity>

        {getPageDisplay().map((pageNumber, index) => (
          <View key={index} style={{ margin: 4 }}>
            {pageNumber === '...' ? (
              <Text>{pageNumber}</Text>
            ) : (
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor:
                      currentPage === pageNumber ? paperNativeTheme.colors.primary : ezTheme.white,
                    padding: 8,
                  },
                ]}
                onPress={() => onPageChange(pageNumber)}>
                <View
                  style={[
                    styles.pageNumber,
                    {
                      backgroundColor:
                        currentPage === pageNumber
                          ? paperNativeTheme.colors.primary
                          : ezTheme.white,
                    },
                  ]}>
                  <Text
                    style={{
                      color: currentPage === pageNumber ? ezTheme.white : ezTheme.black,
                    }}>
                    {pageNumber}
                  </Text>
                </View>
              </TouchableOpacity>
            )}
          </View>
        ))}

        <TouchableOpacity
          style={[
            styles.button,
            {
              marginLeft: 4,
              flexDirection: 'row',
              padding: 4,
              opacity: nextButtonDisabled ? 0.5 : 1,
            },
          ]}
          onPress={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}>
          <Text style={{ marginLeft: 4 }}>Next</Text>
          <Icon name="chevron-right" style={{ width: 20, height: 20 }} />
        </TouchableOpacity>
      </View>

      <Text style={{ marginLeft: 10, alignSelf: 'center' }}>{`Page`}</Text>

      <View style={styles.goToContainer}>
        <TextInput
          style={styles.input}
          keyboardType="numeric"
          value={goToPage}
          onChangeText={(val) => {
            setGoToPage(val);
            setError('');
          }}
          onFocus={() => setPreviousGoToPage(goToPage)}
          onBlur={onBlurText}
        />
        <Text style={{ marginLeft: 3 }}>{`of ${totalPages}`}</Text>
      </View>
      {error ? (
        <Text style={{ color: paperNativeTheme.colors.error, marginLeft: 20 }}>{error}</Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    alignSelf: 'center',
    color: ezTheme.darkgray,
  },
  paginationContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 20,
  },
  button: {
    backgroundColor: ezTheme.white,
    borderRadius: 4,
    borderWidth: 0.5,
    borderColor: ezTheme.darkgray,
  },
  pageNumber: {
    minWidth: 10,
    height: 10,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  goToContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    borderWidth: 0.5,
    padding: 4,
    width: 30,
    height: 30,
    borderRadius: 4,
    textAlign: 'center',
    marginLeft: 3,
  },
});

export default EzPagination;
