import { gql } from '@apollo/client';
import { makeAutoObservable } from 'mobx';

const CHECK_LOGGED_IN = gql`
  query isXeroConnected {
    isXeroConnected
  }
`;

const GET_INVOICE_AS_PDF = gql`
  query getInvoiceAsPdf(
    $warehouseAccountIds: [ID!]!
    $month: Int
    $year: Int
    $organisationId: ID!
  ) {
    getInvoiceAsPdf(
      warehouseAccountIds: $warehouseAccountIds
      month: $month
      year: $year
      organisationId: $organisationId
    )
  }
`;

const GET_EARLISET_TRANSACTION_DATE = gql`
  query getEarliestTransactionTime($orgId: ID!) {
    getEarliestTransactionTime(orgId: $orgId)
  }
`;

const XERO_AUTH_URL = gql`
  query xeroAuthUrl {
    xeroAuthUrl
  }
`;

const XERO_LOGOUT = gql`
  mutation logOutXero {
    logOutXero
  }
`;

export const getMonthYearArray = (year, startTime) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  let fromTime = startTime ?? new Date();

  const monthLimit = year === currentYear ? currentMonth + 1 : 12;

  const result = Array.from({ length: monthLimit }, (_, i) => {
    if (year === fromTime.getFullYear() && i >= fromTime.getMonth()) {
      return { month: i + 1, year: fromTime.getFullYear() };
    } else if (year !== fromTime.getFullYear()) {
      return { month: i + 1, year: year };
    }
  });

  return result.filter((time) => time);
};

export class XeroStore {
  loading = false;
  error = null;
  client = null;
  warehouseAccountStore = null;

  constructor(client, warehouseAccountStore) {
    this.client = client;
    this.warehouseAccountStore = warehouseAccountStore;

    makeAutoObservable(
      this,
      {
        client: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  async getEarliestTransactionTime(organisationId) {
    try {
      this.loading = true;
      const { data } = await this.client.query({
        query: GET_EARLISET_TRANSACTION_DATE,
        variables: {
          orgId: organisationId,
        },
        fetchPolicy: 'no-cache',
      });

      this.error = null;
      return data?.getEarliestTransactionTime || null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  async getInvoiceAsPdf(month, year, organisationId) {
    try {
      this.loading = true;
      const { data } = await this.client.query({
        query: GET_INVOICE_AS_PDF,
        variables: {
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
          month,
          year,
          organisationId,
        },
        fetchPolicy: 'no-cache',
      });

      this.error = null;
      return data?.getInvoiceAsPdf || null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  async getXeroUrlAuth() {
    try {
      this.loading = true;
      const { data } = await this.client.query({
        query: XERO_AUTH_URL,
        fetchPolicy: 'no-cache',
      });

      this.error = null;
      return data?.xeroAuthUrl || null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  async getIsLoggedIn() {
    try {
      this.loading = true;
      const { data } = await this.client.query({
        query: CHECK_LOGGED_IN,
        fetchPolicy: 'no-cache',
      });

      this.error = null;
      return data?.isXeroConnected || null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  get isLoggedIn() {
    return this.getIsLoggedIn();
  }

  async logOut() {
    try {
      this.loading = true;
      await this.client.mutate({
        mutation: XERO_LOGOUT,
      });

      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }
}
